import * as React from "react"
import ContentOnHover from "./contentOnHover"
import { PopoverProps } from "@common/popover"


// TODO - interface cleanup. triggerClass should just be className on Trigger component
export type TooltipProps = {
  children: React.ReactNode
  className?: string
  triggerClass?: string
  isEnabled?: boolean
  offset?: [number, number]
  isMaxContent?: boolean
  isInteractive?: boolean
} & Pick<PopoverProps, "position" | "strategy">

function Tooltip({
  children,
  className,
  triggerClass,
  isEnabled = true,
  position,
  isMaxContent = false,
  strategy,
  isInteractive,
  offset,
}: TooltipProps) {
  return (
    <ContentOnHover
      className={
        className ||
        "p-2 text-xs shadow-elevate1 shadow-zinc-200 dark:shadow-zinc-800 break-words break-word"
      }
      triggerClass={triggerClass}
      isEnabled={isEnabled}
      position={position}
      isMaxContent={isMaxContent}
      strategy={strategy}
      isInteractive={isInteractive}
      offset={offset}
    >
      {children}
    </ContentOnHover>
  )
}

interface TriggerProps {
  children: React.ReactNode
}

function Trigger({ children }: TriggerProps) {
  return <>{children}</>
}

interface ContentProps {
  children: React.ReactNode
}

function Content({ children }: ContentProps) {
  return <>{children}</>
}

Tooltip.Trigger = Trigger
Tooltip.Content = Content

export default Tooltip
