import React from "react"
import {
  descriptionVariants,
  mainHeaderVariants,
  shortHeaderVariants,
} from "@common/header/interfaces"

export interface HeaderProps {
  children: any
  variant?: "h7" | "h6" | "h5" | "h4" | "h3" | "h2" | "h1"
  alignment?: "left" | "center" | "right"
  color?: "primary" | "secondary" | "accent"
  transform?: "uppercase" | "normalcase"
  width?: "full" | "fit"
}

export function Header({ children, variant, alignment }: HeaderProps) {
  if (!children) return <></>
  return (
    <div className="flex flex-col space-y-3">
      {React.Children.map(children, (child: JSX.Element) => {
        if (React.isValidElement<any>(child)) {
          return React.cloneElement(child, { variant, alignment })
        }
        return child
      })}
    </div>
  )
}

Header.ShortHeader = function ShortHeader({ children, ...rest }: HeaderProps) {
  if (!children) return <></>
  return <div className={shortHeaderVariants(rest)}>{children}</div>
}

Header.MainHeader = function MainHeader({
  children,
  variant,
  alignment,
}: HeaderProps) {
  if (!children) return <></>
  return <div className={mainHeaderVariants({ variant, alignment })}>{children}</div>
}

Header.Description = function Description({
  children,
  variant,
  alignment,
}: HeaderProps) {
  if (!children) return <></>
  return (
    <div className={descriptionVariants({ variant, alignment })}>{children}</div>
  )
}
