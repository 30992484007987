import { useState, useEffect } from "react"
import Logo from "@common/logo"
import Svg from "@common/svg"
import Modal from "@common/modal/modal"
import PropTypes from "prop-types"
import LinkButton from "@common/buttons/linkButton"
import Button from "@common/buttons/button"

Header.propTypes = {
  logoObj: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    viewBox: PropTypes.string,
    path: PropTypes.string,
  }).isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
  mobileLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    })
  ),
  siteName: PropTypes.string.isRequired,
  primaryColorClass: PropTypes.string,
  isPrimaryCta: PropTypes.bool,
  isSecondaryCta: PropTypes.bool,
  primaryCta: PropTypes.string,
  secondaryCta: PropTypes.string,
  primaryCtaLink: PropTypes.string,
  secondaryCtaLink: PropTypes.string,
}

export default function Header({
  logoObj,
  links = [],
  siteName,
  primaryColorClass = "btn-blue",
  isPrimaryCta = true,
  isSecondaryCta = true,
  primaryCta = "Sign up",
  secondaryCta = "Sign in",
  primaryCtaLink,
  secondaryCtaLink,
  mobileLinks, //if present > will override menu inside hamburger menu (mobile menu)
  isDarkMode,
}) {
  const [isOpen, setIsOpen] = useState(false) // for mobile header
  useEffect(() => {
    if (isOpen == true) document.body.classList.add("overflow-hidden")
    else document.body.classList.remove("overflow-hidden")
  }, [isOpen])

  return (
    <div className="relative">
      <div className="flex justify-between items-center py-4">
        {renderLogo()}

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <Button size="icon" variant="minimal" onClick={() => setIsOpen(true)}>
            <span className="sr-only">Open menu</span>
            <Svg name="menu" classes="h-6 w-6" />
          </Button>
        </div>
        {/* 
          NOTE: Un-comment NavDesktopMenu and delete looped links
          */}
        <nav className="hidden md:flex lg:space-x-10">
          {links?.map((link, i) => (
            <LinkButton
              target="_blank"
              key={i}
              href={link.link}
              ariaLabel="link"
              rel="noreferrer"
              variant="gray"
              size="md"
            >
              {link.name}
            </LinkButton>
          ))}
        </nav>
        {/* <NavDesktopMenu headerLinks={links} /> */}
        {renderActions()}
      </div>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {renderMobileMenu()}
      </Modal>
    </div>
  )

  function renderActions() {
    return (
      <div className="hidden md:flex items-center justify-end space-x-8">
        {isSecondaryCta == false ? null : (
          <LinkButton
            href={secondaryCtaLink || "/login"}
            variant="minimal"
            aria-label="login"
            size="md"
          >
            {secondaryCta || "Sign in"}
          </LinkButton>
        )}
        {isPrimaryCta == false ? null : (
          <LinkButton
            href={primaryCtaLink || "/signup"}
            aria-label="signup"
            variant="primary"
            size="md"
          >
            {primaryCta || "Sign up"}
          </LinkButton>
        )}
      </div>
    )
  }

  function renderLogo() {
    return (
      <div>
        <span className="sr-only">{siteName}</span>
        <div className="h-8">
          <Logo logoObj={logoObj} isDarkMode={isDarkMode} url="/" />
        </div>
      </div>
    )
  }

  function renderMobileMenu() {
    return (
      <div className="flex flex-col h-[96dvh] md:w-full md:max-w-md">
        <div className="pt-5 pb-6 px-5 space-y-6 flex-grow">
          {/* header */}
          <div className="flex items-center justify-between">{renderLogo()}</div>

          {/* body */}
          <nav className="grid gap-y-8">
            {(mobileLinks ?? links)?.map((link, i) => {
              return (
                <a
                  aria-label="link"
                  key={i}
                  href={link.link}
                  className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 link"
                >
                  <Svg
                    name={link.icon}
                    classes="flex-shrink-0 h-6 w-6 text-blue-theme"
                  />
                  <div className="text-base leading-6 font-medium text-gray-900">
                    {link.name}
                  </div>
                </a>
              )
            })}
          </nav>
          {/* secondary links */}
          {/*
            <div className="grid grid-cols-2 gap-y-4 gap-x-8">
              {
                links.map((link, i) => <a key={i} href="#" className="link text-gray-900"> {link.name} </a>)
              }
            </div>
            */}
        </div>

        {/* footer */}
        <div className="space-y-6 py-6 px-5">
          <div className="space-y-6">
            {isPrimaryCta && (
              <LinkButton
                href={primaryCtaLink || "/signup"}
                aria-label="signup"
                variant="primary"
                size="md"
                width="full"
              >
                {primaryCta || "Sign up"}
              </LinkButton>
            )}
            {isSecondaryCta && (
              <p className="text-center text-base font-medium leading-6 text-secondary">
                Existing customer?&nbsp;
                <LinkButton
                  href={primaryCtaLink || "/signup"}
                  aria-label="signup"
                  variant="link"
                >
                  {secondaryCta || "Sign in"}
                </LinkButton>
              </p>
            )}
          </div>
        </div>
      </div>
    )
  }
  //Note: Uncomment and remove renderMobileMenu
  // function renderNewMobileMenu() {
  //   return (
  //     <Modal.Layout widthClasses="w-[95vw] " heightClasses="h-[95vh]">
  //       <Modal.Header>{renderLogo()}</Modal.Header>
  //       <Modal.Content>
  //         <NavMobileMenu headerLinks={links} />
  //       </Modal.Content>
  //       <Modal.Footer>
  //         {isPrimaryCta == false ? null : (
  //           <LinkButton
  //             variant="accent"
  //             width="full"
  //             href={primaryCtaLink || "/signup"}
  //             aria-label="signup"
  //           >
  //             {primaryCta || "Sign up"}
  //           </LinkButton>
  //         )}
  //         {isSecondaryCta == false ? null : (
  //           <LinkButton
  //             variant="minimal"
  //             href={secondaryCtaLink || "/login"}
  //             width="full"
  //             aria-label="login"
  //           >
  //             Existing customer?&nbsp;{" "}
  //             <span className="text-accent">{secondaryCta || "Sign in"}</span>
  //           </LinkButton>
  //         )}
  //       </Modal.Footer>
  //     </Modal.Layout>
  //   )
  // }
}
