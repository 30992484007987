import { SITE_CONFIG } from "configs/site"
import { landingLayoutParams } from "@common/layouts/landingLayout"
import { NextPage } from "next"
import LinkButton from "@common/buttons/linkButton"
import { Header } from "@common/header/headerUnit"

Home.getLayout = (page: NextPage) =>
  landingLayoutParams(page, { siteConfig: SITE_CONFIG })

export default function Home() {
  return (
    <section className="h-full flex flex-col justify-center items-center m-4">
      <div className="text-center py-4">
        <Header.MainHeader alignment="center" variant="h5">
          bot experiments to auto-write blogs.
        </Header.MainHeader>
      </div>
      <div className="w-full max-w-md">
        <LinkButton
          href="/login"
          aria-label="signup"
          variant="primary"
          size="md"
          width="full"
        >
          Sign up
        </LinkButton>
      </div>
    </section>
  )
}
