import React from "react"
import PropTypes from "prop-types"
import styles from "./loader.module.css"
import Svg from "./svg"

Loader.propTypes = {
  type: PropTypes.string, // default: 'three-dot'. Spin,
  text: PropTypes.string, // default: "Please Wait"
}

export default function Loader({ type, text }) {
  return (
    <div className="h-full flex justify-center items-center gap-2">
      {type == "spin" ? (
        <Svg name="arrow-path" classes="animate-spin h-4 w-4 " />
      ) : type == "spinWithText" ? ( // text besides spinner makes sure the loading state of button is'nt jumpy by inheriting the text properties.
        <>
          <Svg name="arrow-path" classes="animate-spin h-4 w-4 " />{" "}
          {text || "Please wait"}
        </>
      ) : (
        <div className={styles.spinner}>
          <div className={"bg-blue-300 opacity-50 " + styles.bounce1} />
          <div className={"bg-blue-300 opacity-50 " + styles.bounce2} />
          <div className={"bg-blue-300 opacity-50 " + styles.bounce3} />
        </div>
      )}
    </div>
  )
}
