import * as React from "react"
import usePopperPosition from "../hooks/usePopperPosition"
import { Portal } from "@radix-ui/react-portal"

/*
 * TODO - find a solution to override base styles in a reliable manner.
 *
 * className -> only changes the classes for the content.
 */

export default function ContentOnHover({
  children,
  className,
  triggerClass,
  isEnabled,
  clickType = false,
  position = "auto",
  isMaxContent = true,
  strategy,
  isInteractive,
  offset,
}) {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperPosition(position, clickType, strategy, isInteractive, offset)

  const isVisible = visible && isEnabled

  if (!Array.isArray(children)) return
  const [trigger, content] = children
  const hasContent = !!content?.props?.children

  return (
    <div className="relative min-w-0 h-full">
      <div
        className={triggerClass || " max-w-full truncate h-full"}
        ref={setTriggerRef}
      >
        {trigger}
      </div>
      {isVisible &&
        hasContent &&
        (strategy === "absolute" ? (
          renderContent()
        ) : (
          <Portal>{renderContent()}</Portal>
        ))}
    </div>
  )

  function renderContent() {
    return (
      <div
        ref={setTooltipRef}
        className={
          "rounded-md shadow-xl flex flex-col w-max p-0 transition-opacity duration-300 z-[99999] bg-background text-foreground border " +
          (isMaxContent ? "" : " max-w-[20rem] ")
        }
        {...getTooltipProps()}
      >
        <div className={className}>{content}</div>
      </div>
    )
  }
}
