// PROJECT AGNOSTIC REUSABLE COMMON NAVIGATION FUNCTIONS GO HERE

import {
  filterObjects,
  getObjectFromJSONString,
  isNullOrEmpty,
} from "@common/lib/util"
import { Router } from "next/router"

// Function returns the path with only the params specified in the params list and deletes all other parameters
// Input -
//   - path -> https://test.com?param1=val1&param2=val2&param3=val3
//   - params -> [param1, param3]
// Output - https://test.com?param2=val2
export function getPathWithParams(path: string, params: string | string[]): string {
  params = Array.isArray(params) ? params : [params]
  const [base, query] = path.split("?")

  if (!query) return path

  const searchParams = new URLSearchParams(query)
  const newSearchParams = new URLSearchParams()

  // set the parameters that are in the params list
  for (const p of params) {
    if (searchParams.has(p)) {
      newSearchParams.set(p, searchParams.get(p) as string)
    }
  }

  return `${base}?${newSearchParams.toString()}`
}

export function getPreviousPath(path: string) {
  if (path) {
    const [pathWithoutQuery, queryParams] = path.split("?")
    const parts = pathWithoutQuery.split("/")
    parts.pop()
    let newPath = parts.join("/")

    // Add the query parameters back to the path
    if (queryParams) {
      newPath += "?" + queryParams
    }
    return newPath
  }
}
export function addQueryParam(path: string, param: string, value: string) {
  if (!param || !value) return path
  let [basePath, queryString] = path.split("?")
  const searchParams = new URLSearchParams(queryString)
  searchParams.set(param, value)

  // constructing the url manually since searchParams.toString() encodes "," separated value
  for (const [key, value] of searchParams) {
    if (basePath.includes("?")) basePath += `&${key}=${value}`
    else basePath += `?${key}=${value}`
  }

  return basePath
}

// input - ?q1=val1&q2=val2&q3=val3 OR q1=val1&q2=val2&q3=val3
// output - q1=val1&q3=val3 (if q2 has to be removed)
export function removeQueryParamsFromQuerystring(
  querystring: string,
  params: string | string[]
) {
  if (!querystring || isNullOrEmpty(params)) return querystring

  if (querystring.indexOf("?") == -1)
    return removeQueryParams(`?${querystring}`, params).substring(1)
  else if (querystring.indexOf("?") == 0)
    return removeQueryParams(querystring, params).substring(1)
}

export function removeQueryParams(url: string, parameter: string | string[]) {
  if (!url) return ""

  const removeParameter = (url: string, parameter: string): string => {
    // path is passed without querystring
    if (url.indexOf("=") === -1) return url

    //Full url is passed
    if (url.includes("?")) {
      const [path, querystring] = url.split("?")
      const prefix = encodeURIComponent(parameter) + "="
      const pars = querystring.split(/[&;]/g)
      for (let i = pars.length; i-- > 0; ) {
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1)
        }
      }
      return path + (pars.length > 0 ? "?" + pars.join("&") : "")
    } else {
      const prefix = encodeURIComponent(parameter) + "="
      const pars = url.split(/[&;]/g)
      for (let i = pars.length; i-- > 0; ) {
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1)
        }
      }
      return pars.join("&")
    }
  }

  if (typeof parameter === "string") {
    return removeParameter(url, parameter)
  } else if (Array.isArray(parameter)) {
    let newPath = url
    parameter.forEach((param) => {
      newPath = removeParameter(newPath, param)
    })
    return newPath
  }
  return url
}

// Merge query string params while prioritizing params from the first query string
export function mergeQueryStrings(queryString1: string, queryString2: string) {
  const params1 = new URLSearchParams(queryString1)
  const params2 = new URLSearchParams(queryString2)

  params2.forEach((value, key) => {
    if (!params1.has(key)) {
      params1.set(key, value)
    }
  })

  return params1.toString()
}

/*
 * Example usage:
 * const url1 = "freetext.ai/d/corsair_prod/stats?ids=TAG_corsair-crossmarketplace&time=1yr&cat_id=corsair_all&report_id=super_category&label_sub_cat="
 * const url2 = "freetext.ai/d/corsair_prod/stats?ids=TAG_corsair-crossmarketplace&time=1yr&cat_id=corsair_all&label_sub_cat=&report_id=super_category"
 *
 * Returns url without label_sub_cat= in both cases
 */
export function removeInvalidQueryParams(url: string): string {
  const urlParts = url.split("?")

  if (urlParts.length === 2) {
    const [baseUrl, queryString] = urlParts
    const queryParams = queryString.split("&")

    const validQueryParams = queryParams.filter((param) => {
      const [key, value] = param.split("=")
      return value !== "" && value !== "null" && value !== "undefined"
    })

    if (validQueryParams.length > 0) {
      const newQueryString = validQueryParams.join("&")
      return trimTrailingSpecialChars(`${baseUrl}?${newQueryString}`)
    } else {
      return baseUrl
    }
  }

  return trimTrailingSpecialChars(url)
}

export function getPageKey(path: string) {
  if (path) {
    const [pathWithoutQuerystring] = path.split("?")
    const parts = pathWithoutQuerystring.split("/")
    parts.splice(0, 3)
    const key = parts.join("/")
    return key
  }
}

export function replacePageKey(path: string, newPageKey: string) {
  if (path) {
    const pageKey = getPageKey(path)
    const parts = path.split("/")
    const basePath = parts.slice(0, 3).join("/")
    const pagePath = parts.slice(3, parts.length).join("/")
    const newPagePath = pagePath.replace(
      new RegExp("\\b" + pageKey + "\\b"),
      newPageKey
    )
    return basePath.concat("/", newPagePath)
  }
}

// Serialize query string
export const serializeObjectToQueryString = (queryObject: any) => {
  if (isNullOrEmpty(queryObject)) return ""
  const jsonString = JSON.stringify(queryObject)
  return encodeURIComponent(jsonString)
}

// Deserialize object
export const deserializeQueryStringToObject = (encodedQuery: any) => {
  if (!encodedQuery) return {}
  const jsonString = getDecodedURIComponent(encodedQuery)
  return getObjectFromJSONString(jsonString)
}

// regex to remove trailing & or ? characters.
function trimTrailingSpecialChars(input: string): string {
  return input.replace(/[&?]+$/, "")
}

export function getDecodedURIComponent(encodedString: string) {
  try {
    return decodeURIComponent(encodedString)
  } catch (e) {
    return encodedString // if decoding fails, it's not a valid URL-encoded string , returning original string
  }
}

export function getUrlParts(url: string) {
  return url.split("?")
}

/* TODO - replace with better function and name */
export function getFilterValue(query: any, filterKey: string) {
  if (isNullOrEmpty(query) || !filterKey) return
  const value = query[filterKey]
  if (value) return value
  return
}

export function getManagePlansUrl(path: string) {
  const newPath = replacePageKey(path, "settings/plans")
  return newPath
}

export function getHomePageUrl(customerId: string) {
  return `/d/${customerId}/home`
}

export function getHomePath(custId: string, configHomePath: string) {
  if (custId) return `/d/${custId}/${configHomePath}`
}

export const navigate = ({
  router, // the next.js router object
  newPageKey, // the new page key to navigate to
  direction = 1, // the direction of navigation: 1 for forward 0 for update current pageKey and -1 for backward
  newQuery = {}, // the new query parameters to add to the URL
  removedQueryKeys = [], // the keys of query parameters to remove from the URL
  replace = false,
  tab, // if tab="new", this will open an new tab
}: {
  router: Router
  newPageKey: string
  direction?: number
  newQuery?: Record<string, string>
  removedQueryKeys?: Array<string>
  replace?: boolean
  tab?: "new"
}) => {
  if (!router) return //throw Error("Router object should not be null")
  if (newPageKey === "/") router.push("/")

  const [pathname] = router.asPath.split("?")
  const currentPageKey = pathname.split("/")?.at(-1)
  let newPath
  if (newPageKey) {
    if (direction === 1) newPath = `${pathname}/${newPageKey}`
    if (direction === 0) {
      newPath = pathname.replace(currentPageKey, newPageKey)
    }
    if (direction === -1 && pathname.includes(newPageKey)) {
      newPath = pathname.substring(
        0,
        pathname.indexOf(newPageKey) + newPageKey.length
      )
    }
  }

  const query = { ...router.query, ...newQuery } as Record<string, string> // add new query to existing one

  // remove all the queries passed to `@removedQueryKeys`
  const filteredQuery = filterObjects(
    query,
    (_, key) =>
      !removedQueryKeys.includes(key) && !["customerId", "docId"].includes(key)
  )

  const path = newPath || pathname
  if (tab == "new") {
    const queryString = new URLSearchParams(filteredQuery).toString()
    if (window) {
      if (queryString) window.open(`${path}?${queryString}`, "_blank")
      else window.open(path, "_blank")
    }
    return
  }

  if (replace) {
    router.replace({
      pathname: path,
      query: filteredQuery,
    })
  } else {
    router.push({
      pathname: path,
      query: filteredQuery,
    })
  }
}
