import { useRouter } from "next/router"
import React from "react"

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>

export default function Image({ src, ...rest }: ImageProps) {
  const router = useRouter()
  const { basePath } = router
  const finalSrc =
    basePath && src && basePath.startsWith("/") ? `${basePath}${src}` : src

  return <img src={finalSrc} {...rest} />
}
