import { LoginTypeEnum } from "@common/types"
import { SettingsEnum } from "@common/types"

export const plans = {
  writer_trial: {
    plan_id: "writer_trial",
    name: "Trial",
    description: "7 day free trial.",
    cta: "Start Trial",
    features: [
      {
        heading: "INCLUDED",
        features: [
          {
            featureName: "300 words / week",
            featureDesc: null,
          },
          {
            featureName: "1 User",
            featureDesc: null,
          },
        ],
      },
      {
        heading: "FEATURES",
        features: [
          {
            featureName: "Basic Writing Tools",
            featureDesc: null,
          },
          {
            featureName: "Grammar Check",
            featureDesc: null,
          },
        ],
      },
    ],
  },
  writer_small: {
    plan_id: "writer_small",
    name: "Small",
    description: "Basics to get started.",
    cta: "Buy Small",
    features: [
      {
        heading: "INCLUDED",
        features: [
          {
            featureName: "350 words / month",
            featureDesc: null,
          },
          {
            featureName: "1 User",
            featureDesc: null,
          },
        ],
      },
      {
        heading: "FEATURES",
        features: [
          {
            featureName: "Advanced Writing Tools",
            featureDesc: null,
          },
          {
            featureName: "Grammar & Style Check",
            featureDesc: null,
          },
          {
            featureName: "Plagiarism Detection",
            featureDesc: null,
          },
        ],
      },
    ],
  },
  writer_medium: {
    plan_id: "writer_medium",
    name: "Medium",
    description: "Somewhere in between.",
    cta: "Buy Medium",
    features: [
      {
        heading: "INCLUDED",
        features: [
          {
            featureName: "450 words / month",
            featureDesc: null,
          },
          {
            plan_type: "paid",

            featureName: "3 Users",
            featureDesc: null,
          },
        ],
      },
      {
        heading: "← PLUS",
        features: [
          {
            featureName: "AI Writing Assistant",
            featureDesc: null,
          },
          {
            featureName: "Team Collaboration",
            featureDesc: null,
          },
          {
            featureName: "Custom Templates",
            featureDesc: null,
          },
        ],
      },
    ],
  },
  writer_large: {
    plan_id: "writer_large",
    name: "Large",
    description: "Scale up and track.",
    cta: "Buy Large",
    features: [
      {
        heading: "INCLUDED",
        features: [
          {
            featureName: "450 words / month",
            featureDesc: null,
          },
          {
            featureName: "7 Users",
            featureDesc: null,
          },
        ],
      },
      {
        heading: "← PLUS",
        features: [
          {
            featureName: "Advanced Analytics",
            featureDesc: null,
          },
          {
            featureName: "Priority Support",
            featureDesc: null,
          },
          {
            featureName: "Custom Branding",
            featureDesc: null,
          },
          {
            featureName: "API Access",
            featureDesc: null,
          },
        ],
      },
    ],
  },
}

export const SITE_CONFIG = {
  homePath: "home",
  ga: "G-W0S1V579N3",
  favicon: "faviconCr.ico",
  loginType: [LoginTypeEnum.GoogleLogin, LoginTypeEnum.Magiclink],
  logo: {
    white: "logo-small-wide.svg",
    black: "logo-small-wide.svg",
    // "icon-white": "currents-icon-white.svg",
    // "icon-black": "currents-icon-black.svg",
    // "icon-blue": "currents-logo-blue.svg",
    svgName: "currents",
  },
  siteName: "ACME.BOT",
  site: "acme",
  siteShort: "ACME.BOT",
  company: "ACME.BOT",
  address: {
    line1: "68 Circular Road",
    line2: "#02-01 Singapore (049422)",
  },
  seo: {
    url: "https://acme.bot",
    logoUrl: "https://acme.bot/images/acme-112-black.png",
    title: "AI tools to run your business - Acme Bot",
    description:
      "AI tools and agents - Web Research, Writer, Diagram Generator, Content Planner, etc - to automate the most tedious parts of your business.",
    homeImageUrl: "https://acme.bot/images/acme-og.png",
    siteName: "ACME.BOT",
    locale: "en_US",
  },
  footer: {
    visible: false,
  },
  footerSummary:
    "AI tools and agents to automate the most tedious parts of your business.",
  contactEmail: "human@acme.bot",
  faqs: [],
  footerLinks: [],
  pricing: {
    plans: plans,
    hideEnterprise: true,
  },
  headerLinks: [],
  headerCtaLinks: [
    {
      name: "Sign in",
      link: "/login",
    },
    {
      name: "Sign Up",
      link: "/login",
    },
  ],
  /**
   * NOTE:
   * rename this as headerCtaLinks (delete old one)
   * when header.js uses new NavDesktopMenu component
   */
  headerCtaLinksNew: [
    {
      display: "Sign in",
      links: "/login",
    },
    {
      display: "Sign Up",
      links: "/login",
    },
  ],

  helpLink: "https://acme.bot/docs",
  settingsConfig: [SettingsEnum.appearance],
}
