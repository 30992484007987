import { cva, type VariantProps } from "class-variance-authority"

export type ContainerSize = "8xl" | "9xl" | "full" | "landing"

const containerVariants = cva("", {
  variants: {
    size: {
      "8xl": "max-w-8xl px-4 sm:px-6",
      "9xl": "max-w-9xl px-4 sm:px-6",
      landing: "max-w-7xl h-full px-4 sm:px-6 lg:px-8",
      full: "max-w-full max-h-full px-2 md:px-6 xl:px-12",
    },
    alignment: {
      center: "mx-auto",
      left: "",
    },
  },
  defaultVariants: {
    size: "full",
    alignment: "center",
  },
})

export interface ContainerProps extends VariantProps<typeof containerVariants> {
  children: React.ReactNode
  size?: ContainerSize
}

export default function Container({ children, size, alignment }: ContainerProps) {
  return <div className={containerVariants({ size, alignment })}>{children}</div>
}
