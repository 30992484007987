import { NextRouter } from "next/router"

export function getCustomerId(router: NextRouter) {
  return router.query.customerId as string
}

// incase of public_reports {shared_token} will be pageKey
export function getShareableUrlId(queryObject: Record<string, any> = {}) {
  const { shared_id } = queryObject
  return shared_id
}
