import React, { ErrorInfo, ReactNode } from "react"
import SourceMap from "source-map-js"
import { useRouter } from "next/router"
import Button from "@common/buttons/button"
import { recordErrorInForm } from "@common/lib/util"

interface ErrorBoundaryProps {
  children: ReactNode
  fallback?: ReactNode
  homeUrl?: string
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_error: Error): ErrorBoundaryState {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (process.env.NODE_ENV === "production") {
      this.logError(error, errorInfo)
    } else {
      console.error("Error caught by ErrorBoundary:", error, errorInfo)
    }
  }

  async logError(error: Error, errorInfo: ErrorInfo) {
    try {
      const mappedStack = await this.mapStackTrace(errorInfo?.componentStack)
      // Replace this with your preferred error logging service
      console.error("Logged error:", {
        message: error?.message,
        stack: mappedStack,
        originalStack: errorInfo?.componentStack,
      })
      recordErrorInForm(
        error.message,
        `${error?.message}, originalStack: ${errorInfo?.componentStack}`
      )
    } catch (mappingError) {
      console.error("Error mapping stack trace:", mappingError)
    }
  }

  async getSourceMapForUrl(url: string): Promise<SourceMap.SourceMapConsumer> {
    const mapUrl = `${url}.map`
    const response = await fetch(mapUrl)
    const text = await response.text()
    return new SourceMap.SourceMapConsumer(JSON.parse(text))
  }

  async mapStackTrace(stackTrace: string): Promise<string> {
    const lines = stackTrace.trim().split("\n")
    const mappedLines = await Promise.all(
      lines.map(async (line) => {
        const match = /at (.+?) \((.+?):(\d+):(\d+)\)/.exec(line)
        if (!match) return line
        const [, funcName, url, lineNo, colNo] = match
        const consumer = await this.getSourceMapForUrl(url)
        const mapped = consumer.originalPositionFor({
          line: parseInt(lineNo, 10),
          column: parseInt(colNo, 10),
        })

        return `at ${funcName} (${mapped.source}:${mapped.line}:${mapped.column})`
      })
    )
    return mappedLines.join("\n")
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback || <ErrorFallback homeUrl={this.props.homeUrl} />
    }
    return this.props.children
  }
}

export const ErrorFallback = ({ homeUrl }: { homeUrl: string }) => {
  const router = useRouter()
  const handleGoHome = () => {
    router.push(homeUrl).then(() => router.reload())
  }
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="text-center space-y-4">
        <h1 className="text-4xl-r font-extrabold text-primary tracking-tight">
          Oops, there is an error!
        </h1>
        <div className="flex space-x-4">
          <Button onClick={() => router.reload()} variant="outline">
            Try again
          </Button>
          <Button onClick={handleGoHome} variant="primary">
            Go To Dashboard Home
          </Button>
        </div>
      </div>
    </div>
  )
}
export default ErrorBoundary
