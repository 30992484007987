import Link from "next/link"
import Image from "@common/image"
import { useEffect, useState } from "react"

interface LogoProps {
  isDarkMode?: boolean
  logoObj: Record<string, string>
  color?: "white" | "black" | "blue"
  version?: "icon" | "full"
  url?: string
  className?: string
}

export default function Logo({
  isDarkMode = false,
  logoObj,
  color,
  version = "full",
  url,
}: LogoProps) {
  const [logo, setLogo] = useState<string>()
  useEffect(() => {
    const src = getLogoSrc(isDarkMode, color)
    setLogo(src)
  }, [isDarkMode, color])

  if (!logo) return null

  const LogoImage = (
    <Image
      className="h-full w-full object-contain"
      src={`/logo/${logo}`}
      alt="logo"
    />
  )

  return (
    <div className="h-full w-auto">
      {url ? <Link href={url}>{LogoImage}</Link> : LogoImage}
    </div>
  )

  function getLogoSrc(isDarkMode: boolean, color: string) {
    const col = color || (isDarkMode ? "white" : "black")
    const logoKey = version === "icon" ? `icon-${col}` : col
    const logo = logoObj[logoKey]
    return logo
  }
}
