import { usePopperTooltip } from "react-popper-tooltip"

// placement: 'auto' | 'auto-start' | 'auto-end' | 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'right' | 'right-start' | 'right-end' | 'left' | 'left-start' | 'left-end';

export default function usePopperPosition(
  position,
  triggerType,
  strategy = "fixed",
  interactive = true,
  onVisibleChange,
  offset,
  defaultVisible
) {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
    triggerRef,
  } = usePopperTooltip(
    {
      placement: position,
      interactive,
      delayHide: 100,
      trigger: triggerType ? "click" : "hover",
      offset: offset || [0, 2],
      onVisibleChange: (visibility) => onVisibleChange?.(visibility),
      defaultVisible,
    },
    { strategy }
  )
  return {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
    triggerRef,
  }
}
