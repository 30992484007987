import Svg from "./svg"
import Logo from "./logo"
import { Container8xl } from "./container"
import PropTypes from "prop-types"
import { isNullOrEmpty } from "./lib/util"

Footer.propTypes = {
  footerLinks: PropTypes.shape({
    social: PropTypes.shape({
      name: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          link: PropTypes.string,
        })
      ),
    }),
    seoLinks: PropTypes.shape({
      1: PropTypes.shape({
        name: PropTypes.string,
        links: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            link: PropTypes.string,
          })
        ),
      }),
    }),
  }),
  footerSummary: PropTypes.string,
  email: PropTypes.string,
  company: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  logoObj: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
}

export default function Footer({
  footerLinks,
  footerSummary,
  email,
  company,
  line1,
  line2,
  logoObj,
  isDarkMode,
}) {
  if (isNullOrEmpty(footerLinks)) return <div></div>

  return (
    <footer className="bg-transparent pt-6 md:pt-10" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <Container8xl>
        <div className=" py-12 lg:py-28 ">
          <div className="flex flex-col lg:flex-row justify-between gap-8 ">
            <div>
              <div className="h-8 md:h-10 w-fit">
                <Logo logoObj={logoObj} isDarkMode={isDarkMode} url="/" />
              </div>

              <p className="text-secondary text-base my-4">{footerSummary}</p>
              {email && <p className="text-secondary text-base my-4">{email}</p>}
              <Address company={company} line1={line1} line2={line2} />
              <div className="flex ">
                {footerLinks && "social" in footerLinks
                  ? footerLinks["social"]["links"].map((link, i) => (
                      <div className="p-2" key={i}>
                        <SocialLink idx={i} name={link.name} link={link.link} />
                      </div>
                    ))
                  : null}
              </div>
            </div>

            <div className=" flex flex-wrap justify-between lg:justify-evenly w-full ">
              {"primary" in footerLinks
                ? Object.keys(footerLinks["primary"]).map((col, i) => {
                    const column = footerLinks["primary"][col]
                    return (
                      <div key={i} className="mt-4">
                        <LinkHeader name={column.name} />
                        <LinkCol col={column} />
                      </div>
                    )
                  })
                : null}
            </div>
          </div>
          {!isNullOrEmpty(footerLinks?.seoLinks) && (
            <div className="mt-12 border-t  pt-10  xl:text-center">
              <SeoLinks seoLinks={footerLinks.seoLinks} />
            </div>
          )}
          <div className="mt-12 border-t  pt-8  xl:text-center">
            <Rights company={company} />
          </div>
        </div>
      </Container8xl>
    </footer>
  )
}

function SeoLinks({ seoLinks }) {
  if (!seoLinks || !seoLinks.links || seoLinks.links.length == 0) return
  return (
    <div className="text-left">
      <LinkHeader name={seoLinks.name} />
      <div className="  mt-4 grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  ">
        {seoLinks.links?.map((link, i) => {
          return <Link key={i} idx={i} name={link.name} link={link.link} />
        })}
      </div>
    </div>
  )
}

function LinkCol({ col }) {
  return (
    <ul className="mt-4 space-y-4">
      {col.links.map((link, i) => {
        return (
          <li key={i}>
            <Link idx={i} name={link.name} link={link.link} />
          </li>
        )
      })}
    </ul>
  )
}

function Address({ company, line1, line2 }) {
  return (
    <div className="text-secondary text-base">
      {company}
      <div>{line1}</div>
      <div>{line2}</div>
    </div>
  )
}

function SocialLink({ name, link, idx }) {
  return (
    <a
      aria-label="link"
      href={link}
      key={idx}
      className="text-gray-700 hover:text-gray-900"
    >
      <span className="sr-only">{name}</span>
      <Svg name={name} classes="h-6 w-6" fill="currentColor" />
    </a>
  )
}

export function FooterSimple({ footerLinks }) {
  if (!footerLinks || !("simple" in footerLinks)) return <div></div>

  return (
    <footer className="bg-background mt-6 ">
      <div className="max-w-7xl mx-auto pt-12 pb-4 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2" aria-label="Footer">
          <ul className=" flex flex-wrap justify-center">
            {footerLinks["simple"].links.map((link, i) => (
              <Link
                key={i}
                idx={i}
                name={link.name}
                link={link.link}
                className="px-5 py-2"
              />
            ))}
          </ul>
        </nav>

        <div className="mt-8 text-center">
          <Rights />
        </div>
      </div>
    </footer>
  )
}

function Rights({ company }) {
  const date = new Date()
  const year = date.getFullYear()

  return (
    <p className="text-xs md:text-base text-secondary">
      &copy; {year} {company} All rights reserved.
    </p>
  )
}

function LinkHeader({ name }) {
  return (
    <h3 className="text-sm font-semibold text-primary tracking-wider uppercase">
      {name}
    </h3>
  )
}

function Link({ name, link, className, idx }) {
  if (!link || link.length == 0)
    return (
      <div
        key={idx}
        className={
          "text-base text-secondary dark:hover:text-gray-200 opacity-90" +
          (className ? className : "")
        }
      >
        {name}
      </div>
    )

  return (
    <div className={className}>
      <a
        aria-label="link"
        href={link}
        className="text-secondary text-sm hover:text-gray-900 dark:hover:text-gray-200 md:text-base"
      >
        {name}
      </a>
    </div>
  )
}
