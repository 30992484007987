import React, { ReactNode } from "react"
import Tooltip, { TooltipProps } from "@common/tooltip/tooltip"

interface TooltipWrapperProps extends TooltipProps {
  trigger?: ReactNode
  content?: ReactNode
  children: ReactNode
  disabled?: boolean
}

const TooltipWrapper = ({
  trigger,
  content,
  children,
  position,
  disabled = false, //show tooltip
  strategy,
  offset,
  triggerClass,
}: TooltipWrapperProps) => {
  if (disabled || (!trigger && !content)) {
    return <>{children}</>
  }

  if (trigger)
    return (
      <Tooltip
        position={position}
        strategy={strategy}
        triggerClass={triggerClass}
        offset={offset}
      >
        <Tooltip.Trigger>{trigger}</Tooltip.Trigger>
        <Tooltip.Content>{children}</Tooltip.Content>
      </Tooltip>
    )

  if (content)
    return (
      <Tooltip position={position} triggerClass={triggerClass} offset={offset}>
        <Tooltip.Trigger>{children}</Tooltip.Trigger>
        <Tooltip.Content>{content}</Tooltip.Content>
      </Tooltip>
    )

  return <>{children}</>
}

export default TooltipWrapper
