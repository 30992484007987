import Footer from "@common/footer"
import Header from "@common/header"
import { useEffect, useState } from "react"
import { useTheme } from "next-themes"
import Sticky from "@common/sticky"
import Container from "@common/v2/container"

export default function LandingLayout({
  children,
  getFooterLinks,
  siteConfig = {},
  country,
}) {
  const {
    headerLinks,
    mobileLinks,
    headerCtaLinks,
    site,
    logo,
    footerSummary,
    email,
    company,
    address,
  } = siteConfig

  const [footerLinks, setFooterLinks] = useState(siteConfig.footerLinks)

  const { theme } = useTheme()
  const isDarkMode = theme == "dark"
  useEffect(() => {
    _setFooterLinks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, siteConfig.footerLinks])

  async function _setFooterLinks() {
    const newFooterLinks = getFooterLinks
      ? await getFooterLinks(country, footerLinks)
      : footerLinks
    setFooterLinks(newFooterLinks)
  }

  return (
    <div className="flex flex-col h-dvh bg-background">
      <Sticky stickyClasses="border-b" classes="mb-2">
        <header className="backdrop-blur-md">
          <Container size="8xl">
            <Header
              siteName={site}
              logoObj={logo}
              links={headerLinks}
              primaryCtaLink={headerCtaLinks && headerCtaLinks[1]?.link}
              primaryCta={headerCtaLinks && headerCtaLinks[1].name}
              secondaryCtaLink={headerCtaLinks && headerCtaLinks[0].link}
              secondaryCta={headerCtaLinks && headerCtaLinks[0].name}
              mobileLinks={mobileLinks}
              isDarkMode={isDarkMode}
            />
          </Container>
        </header>
      </Sticky>

      <main className="flex-grow flex flex-col">{children}</main>

      {siteConfig.footer?.visible && (
        <footer>
          <Footer
            logoObj={logo}
            footerSummary={footerSummary}
            email={email}
            company={company}
            line1={address && address?.line1}
            line2={address && address?.line2}
            footerLinks={footerLinks}
            isDarkMode={isDarkMode}
          />
        </footer>
      )}
    </div>
  )
}

// context - read option 4 (bonus) here https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
export const getLayout = (page) => <LandingLayout>{page}</LandingLayout>

/**
 *
 * @param {import("next").NextPage} page
 * @param {Object<any>} obj
 * @param { (country:string, footerLinks:any)=>Promise<any>} obj.getFooterLinks
 * @param {Record<string,any>} obj.siteConfig
 * @param {string} obj.country
 * @returns
 */
export const landingLayoutParams = (
  page,
  { getFooterLinks, country, siteConfig }
) => {
  return (
    <LandingLayout {...{ getFooterLinks, country, siteConfig }}>
      {page}
    </LandingLayout>
  )
}
